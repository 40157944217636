<template>
  <div>
    <ProductPage
      v-if="useProductPath"
    />
    <StoryblokComponent
      v-else-if="story"
      :blok="story.content"
    />
    <StoryblokComponent
      v-if="show404page"
      :blok="story404.content"
    />
  </div>
</template>

<script setup lang="ts">
import { Head } from '@unhead/schema';
import { useGlobalContentStore } from '~/store/globalContent';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import useStoryblokAdmin from '~/composeables/useStoryblokAdmin';
import domainMapper from '~/util/domain-mapper';
import { ISbStoriesParams } from 'storyblok-js-client';
import useRouterNormalize from '~/composeables/useRouterNormalize';
const { normalizeQuery } = useRouterNormalize();

const { replaceLinksInPath, replaceLinksInStoryBlokResponse } = useLinkReplacer();
const head = {} as Head;
const globalContent = useGlobalContentStore();
const route = useRoute();
const storyblokAdmin = useStoryblokAdmin();
// looking for the word "filterpage" in url, removes it and everything after
// we use this in ProductList for filtering
let realPathArr = route.path.split('/');
// Temp fix for when accessing the page through storybloks
if (globalContent.getStoryblokBasePath.length && !globalContent.isSubFolder && !storyblokAdmin.isInStoryblokAdmin() && realPathArr?.includes(globalContent.getStoryblokBasePath)) {
  await navigateTo(realPathArr.filter(a => a !== globalContent.getStoryblokBasePath).join('/'));
}
let realPath = route.path;
if ((!realPath.length || realPath === '/') && !globalContent.getStoryblokBasePath.length) {
  realPath = '/start';
}
const removeIndex = realPathArr.findIndex((f)=> f === 'filterpage');
if (removeIndex > -1) {
  realPathArr = realPathArr.slice(0, removeIndex);
  realPath = realPathArr.join('/');
}

realPath = replaceLinksInPath(realPath)?.replaceAll('//', '/');
const story = ref(null);
const story404 = ref(null);
const storyblokApi = useStoryblokApi();

const paths = realPath?.split('/') || [];

const useProductPath = ref(paths.includes(globalContent.getProductBasePath));

const show404page = ref(false);

onErrorCaptured((err)=> {
  if (err.statusCode === 404) {
    setErrorPage();
    return false;
  }
});

const setErrorPage = async() => {
  const sb404 = await storyblokApi.get(`cdn/stories/${globalContent.getStoryblokBasePath}/sitesettings/error-404`, {
    version: 'published',
  });
  story404.value = sb404.data.story;
  show404page.value = true;
  useProductPath.value = false;
};

if (!useProductPath.value) {
  const { data } = await useAsyncData('sbload', async() => {
    let opts = {
      version: useRuntimeConfig().public.storyblokStoriesVersion,
      resolve_links: 'url',
    } as ISbStoriesParams;
    if (storyblokAdmin.isInStoryblokAdmin() && storyblokAdmin.storyblokReleaseId()) {
      opts.from_release = normalizeQuery('_storyblok_release');
    }
    if (process.server && !storyblokAdmin.isInStoryblokAdmin() && globalContent.cv) {
      opts.cv = globalContent.cv;
    }
    return await storyblokApi.get(`cdn/stories/${realPath}`, opts);
  });

  if (data.value) {
    story.value = data.value.data.story;

    onMounted(() => {
      useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory));
    });
    story.value = replaceLinksInStoryBlokResponse(story.value) as any;

    globalContent.setCurrentStory(story.value);
    head.title = story.value.content?.seo?.title || story.value.name;
    if (story.value.content?.seo?.title) {
      head.titleTemplate = (titleChunk: string) => {
        return titleChunk;
      };
    }
    globalContent.setPageUuid(story.value.uuid);
    globalContent.setPageId(story.value.id);

    /**
     * links to other languages
     */
    const currentEnvironment = globalContent.getEnvironment;
    if (story.value?.alternates?.length) {
      const alternates = story.value.alternates.map((i)=> {
        const f = i.full_slug.split('/');
        const storyblokBasePath = f.shift();
        const path = f.join('/');
        return {
          storyblokBasePath,
          path,
        };
      }).filter((f)=> f.storyblokBasePath !== globalContent.getStoryblokBasePath);

      alternates.forEach((a)=> {
        const toLang = domainMapper.find((f) => f.languagePrimary && f.environment === currentEnvironment && f.storyblokBasePath === a.storyblokBasePath);
        if (toLang) {
          useHead({
            link: [{ rel: 'alternate', hreflang: toLang.locale, href: `${toLang.url}/${a.path}` }],
          });
        }
      });

      /**
       * Adds exception for frontpage
       */
    } else if (route.path === '/') {
      domainMapper
        .filter((f)=> f.languagePrimary && f.environment === currentEnvironment && f.storyblokBasePath !== globalContent.getStoryblokBasePath)
        .forEach((d) => {
          useHead({
            link: [{ rel: 'alternate', hreflang: d.locale, href: `${d.url}/` }],
          });
        });
    }

    useHead(head);
  } else {
    setResponseStatus(404);
    setErrorPage();
  }
}
</script>
